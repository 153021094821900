import { get, isArray, isObject } from "lodash";
import {
  getCurrentDomain,
  getDuplicates,
  getGoogleSheetsEndpoint,
  safeArray,
  safeString,
} from "./utils/utils";
import {
  rApp,
  rFetchingBlockIds,
  rPageBlocks,
  rRelationFormChanges,
  rSavedSpreadsheets,
  spreadsheetsSelector,
} from "./utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { apiRequest } from "./utils/apiRequests";
import { successNotification } from "./utils/Notification";
import useActiveBlock from "./utils/useActiveBlock";
import useSetBlock from "./utils/useSetBlock";
import useUtils from "./renderingApp/useUtils";

// Function to get headers and smart fields from data source
export const getHeaders = (spreadsheet) => {
  const fieldData = get(spreadsheet, ["field_data", "config"], {});

  const smartFields = get(spreadsheet, "smart_fields", {});

  const headers = get(spreadsheet, "headers", [])
    .filter((k) => !["frontly_data", "frontly_id", "id"].includes(k))
    .filter((h) => get(fieldData, [h, "active"]) !== false);

  const checkHeader = (field) => (headers.includes(field) ? field : null);

  return {
    headers,
    // Smart Fields (powered by AI)
    primaryImage: checkHeader(get(smartFields, "primary_image", null)),
    statusField: checkHeader(get(smartFields, "status_field", null)),
    primaryLabel: checkHeader(get(smartFields, "primary_label", null)),
    secondaryLabel: checkHeader(get(smartFields, "secondary_label", null)),
    startDate: checkHeader(get(smartFields, "start_date", null)),
    chartValue: checkHeader(get(smartFields, "chart_value", null)),
  };
};

const useSpreadsheetRequests = () => {
  const activeApp = useRecoilValue(rApp);

  const setBlock = useSetBlock();

  const activeBlock = useActiveBlock();

  const { processDynamicText } = useUtils();

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const blocks = useRecoilValue(rPageBlocks);

  const [recoilSpreadsheets, setRecoilSpreadsheets] =
    useRecoilState(spreadsheetsSelector);

  const [fetchingBlockIds, setFetchingBlockIds] =
    useRecoilState(rFetchingBlockIds);

  const getDomain = () => {
    const subdomain = get(activeApp, "subdomain");
    if (subdomain) {
      return `${subdomain}.frontly.ai`;
    }
    return getCurrentDomain();
  };

  const refreshSheet = async ({ sheetId, blockId, refreshObject }) => {
    if (blockId) {
      setFetchingBlockIds([...fetchingBlockIds, blockId]);
    }

    await getRecords({
      sheetId,
      refreshObject,
    }).then((sheetData) => {
      successNotification("Sheet Data Refreshed");

      setSavedSpreadsheets(
        savedSpreadsheets.map((s) => {
          if (s.id == sheetId) {
            return { ...s, ...sheetData };
          }
          return s;
        })
      );

      if (blockId) {
        setFetchingBlockIds(fetchingBlockIds.filter((id) => id !== blockId));
      }
    });
  };

  // GET SINGLE RECORD
  const getRecord = async ({ sheetId, recordId, rowIdColumn }) => {
    const body = {
      spreadsheet_id: sheetId,
      row_id_column: rowIdColumn,
      row_id: processDynamicText({ text: recordId }),
      method: "GET_ROW",
      domain: getDomain(),
    };

    if (!recordId) {
      return null;
    }

    return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
      if (safeString(sheetId).includes("db__")) {
        const data = get(response, "data", {});
        return data;
      } else {
        const data = get(response, ["data", "data"], {});
        // const headers = get(response, ["data", "headers"], []);
        return data;
      }
    });
  };

  // GET ALL RECORDS
  const getRecords = async ({
    sheetId,
    filters,
    pagination,
    refreshObject = null,
  }) => {
    const body = {
      spreadsheet_id: sheetId,
      method: "GET_ALL",
      domain: getDomain(),
      filters,
      pagination,
      refresh_object: refreshObject,
    };

    return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
      const data = get(response, ["data", "data"], []);
      const headers = get(response, ["data", "headers"], []);
      const fieldData = get(response, ["data", "field_data"], {});
      const pagination = get(response, ["data", "pagination"], {});
      return { data, headers, field_data: fieldData, pagination };
    });
  };

  const [relationFormChanges, setRelationFormChanges] =
    useRecoilState(rRelationFormChanges);

  const updateMatchingSheets = ({
    type,
    sheetId,
    recordId,
    values,
    newRecord,
    newRecords,
    currentRecord = {},
  }) => {
    // TRY FINDING ALL INSTANCES OF THIS RECORD IN USE AND UPDATE IT IN REAL TIME
    try {
      let processedRecordId = processDynamicText({ text: recordId });

      const concatId = `${sheetId}__${processedRecordId}`;
      const relationFormChange = get(relationFormChanges, concatId);

      let relationChangeObject = {};

      // If there is a relation form change, we need to add the related record data to the new values
      if (relationFormChange) {
        // Add related record data to the new values (data relations)
        Object.entries(relationFormChange).forEach(([key, value]) => {
          Object.entries(value).forEach(([k, v]) => {
            relationChangeObject[`${key}__${k}`] = v;
          });
        });

        // Set recoil state to remove the relation form change
        let newRelationFormChanges = { ...relationFormChanges };
        delete newRelationFormChanges[concatId];
        setRelationFormChanges(newRelationFormChanges);
      }

      let newSpreadsheets = {};
      // Find all the block ids that use this spreadsheet
      const blocksWithThisSheet = blocks
        .filter((b) => b.spreadsheet === sheetId)
        .map((b) => b.id);

      Object.keys(recoilSpreadsheets).forEach((blockId) => {
        const isValid = blocksWithThisSheet.includes(parseInt(blockId));

        if (isValid) {
          let match = get(recoilSpreadsheets, blockId, []);

          const isFormOrList = ["Form", "InfoList"].includes(
            get(
              blocks.find((b) => b.id === parseInt(blockId)),
              "componentId"
            )
          );

          if (isFormOrList) {
            // FORM - to update the 'block' variable
            if (type === "update") {
              let newData = { ...match };
              values.forEach((v) => {
                newData[v.key] = v.value;
              });
              newSpreadsheets[blockId] = newData;
            }
          } else {
            // Force to array if not already
            match = safeArray(match);

            // NOT FORM
            let newData = [...match];
            if (type === "create_multiple") {
              // Add new record to existing records
              newData = [...newRecords, ...match];
            } else if (type === "create") {
              // Add new record to existing records

              // Add related record data to the new values (data relations)
              newRecord = { ...newRecord, ...relationChangeObject };

              newData = [newRecord, ...match];
            } else if (type === "delete") {
              // Remove matching existing record
              newData = match.filter((r) => r.frontly_id != processedRecordId);
            } else if (type === "update") {
              // Update matching existing record
              newData = match.map((r) => {
                if (r.frontly_id == processedRecordId) {
                  let newValues = { ...r, ...currentRecord };

                  values.forEach((v) => {
                    newValues[v.key] = v.value;
                  });

                  // Add related record data to the new values (data relations)
                  newValues = { ...newValues, ...relationChangeObject };

                  return newValues;
                }
                return r;
              });
            }

            newSpreadsheets[blockId] = newData;
          }
        }
      });

      setRecoilSpreadsheets(newSpreadsheets);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  // UPDATE ROW
  const updateRecord = async ({
    sheetId,
    recordId,
    values,
    rowIdColumn,
    currentRecord = {},
    relatedRecords = {},
  }) => {
    const body = {
      spreadsheet_id: sheetId,
      row_id: processDynamicText({ text: recordId }),
      row_id_column: rowIdColumn,
      method: "UPDATE_ROW",
      domain: getCurrentDomain(),
      values,
    };

    updateMatchingSheets({
      type: "update",
      sheetId,
      recordId,
      values,
      currentRecord,
      relatedRecords,
    });

    return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
      const data = get(response, "data", {});
      return data;
    });
  };

  // CREATE ROW
  const createRecord = async ({ sheetId, values, relatedRecords = {} }) => {
    const body = {
      spreadsheet_id: sheetId,
      method: "CREATE_ROW",
      domain: getCurrentDomain(),
      values,
    };

    return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
      const newRecord = get(response, ["data"], {});
      updateMatchingSheets({
        type: "create",
        sheetId,
        newRecord,
      });
      return newRecord;
    });
  };

  // DELETE ROW
  const deleteRecord = async ({ sheetId, recordId, rowIdColumn }) => {
    const body = {
      spreadsheet_id: sheetId,
      row_id: recordId,
      row_id_column: rowIdColumn,
      method: "DELETE_ROW",
      domain: getCurrentDomain(),
    };

    // Trying without async - jan 24, 2025
    apiRequest.post(getGoogleSheetsEndpoint(), body);

    updateMatchingSheets({ type: "delete", sheetId, recordId });

    return;
  };

  const handleCalendar = (sheetId) => {
    const spreadsheet = savedSpreadsheets.find((s) => s.id === sheetId);
    const { startDate, primaryLabel } = getHeaders(spreadsheet);
    setBlock({
      data: {
        ...listBlockData(sheetId),
        startDate: startDate,
        eventLabel: primaryLabel,
      },
    });
  };

  const handleStat = (sheetId) => {};

  const handleChart = (sheetId) => {
    const spreadsheet = savedSpreadsheets.find((s) => s.id === sheetId);
    const { chartValue, primaryLabel } = getHeaders(spreadsheet);

    setBlock({
      data: {
        ...listBlockData(sheetId),
        labelKey: primaryLabel,
        valueKey: chartValue,
      },
    });
  };

  const handleInfoList = (sheetId) => {
    const d = savedSpreadsheets.find((s) => s.id === sheetId);

    const headers = get(d, "headers", []).filter(
      (k) => !["frontly_data", "frontly_id", "id"].includes(k)
    );

    const sheet = savedSpreadsheets.find((s) => s.id === sheetId);

    const data = {
      ...activeBlock,
      gridLayout: headers.length > 3,
      label: get(sheet, "title"),
      spreadsheet: sheetId,
    };

    setBlock({ data });
  };

  const handleForm = (sheetId) => {
    const d = savedSpreadsheets.find((s) => s.id === sheetId);

    const headers = get(d, "headers", []).filter(
      (k) => !["frontly_data", "frontly_id", "id"].includes(k)
    );

    const sheet = savedSpreadsheets.find((s) => s.id === sheetId);

    setBlock({
      data: {
        ...activeBlock,
        gridLayout: headers.length > 3,
        label: get(sheet, "title"),
        spreadsheet: sheetId,
      },
    });
  };

  const handleGrid = (sheetId) => {
    setBlock({ data: listBlockData(sheetId) });
  };

  const handleCustom = (sheetId) => {
    const spreadsheet = savedSpreadsheets.find((s) => s.id === sheetId);
    setBlock({
      data: {
        ...activeBlock,
        label: get(spreadsheet, "title"),
        spreadsheet: sheetId,
      },
    });
  };

  const listBlockData = (sheetId) => {
    const spreadsheet = savedSpreadsheets.find((s) => s.id === sheetId);
    const { headers, primaryImage, primaryLabel, secondaryLabel } =
      getHeaders(spreadsheet);

    let data = {
      ...activeBlock,
      label: get(spreadsheet, "title"),
      spreadsheet: sheetId,
    };

    // Add image key if it exists
    if (primaryImage) {
      data.image = primaryImage;
    }

    if (get(activeBlock, "componentId") !== "Chart") {
      data["fields"] = getCardFields(primaryLabel, secondaryLabel, headers);
    }

    return data;
  };

  const getCardFields = (primaryLabel, secondaryLabel, headers) => {
    const styles = ["headingLg", "bodyMd", "bodySm"];

    // Both labels are defined
    if (primaryLabel && secondaryLabel) {
      return [
        {
          id: 0,
          key: primaryLabel,
          fontStyle: get(styles, 0),
        },
        {
          id: 1,
          key: secondaryLabel,
          fontStyle: get(styles, 1),
        },
      ];
    }

    // Just primary or secondary, return it and two other columns
    if (primaryLabel || secondaryLabel) {
      const label = primaryLabel || secondaryLabel;

      return [
        {
          id: 0,
          key: label,
          fontStyle: get(styles, 0),
        },
        ...headers
          .filter((h) => h !== label)
          .filter((h, i) => i < 2)
          .map((h, i) => ({
            id: i + 1,
            key: h,
            fontStyle: get(styles, i),
          })),
      ];
    }

    // No smart fields, return 3 fields
    return headers
      .filter((h, i) => i < 3)
      .map((h, i) => ({
        id: i + 1,
        key: h,
        fontStyle: get(styles, i),
      }));
  };

  const handleKanban = (sheetId, colKey = null) => {
    const spreadsheet = savedSpreadsheets.find((s) => s.id === sheetId);
    const { statusField } = getHeaders(spreadsheet);

    if (colKey) {
      // Manually changing the column key in the UI
      populateKanbanColumns({ sheetId, columnKey: colKey });
    } else {
      // Initialize block
      setBlock({
        data: {
          ...listBlockData(sheetId),
          columnKey: statusField,
          columns: [],
        },
      });
    }
  };

  const populateKanbanColumns = ({ sheetId, columnKey }) => {
    const spreadsheet = savedSpreadsheets.find((s) => s.id === sheetId);
    const spreadsheetData = get(spreadsheet, "data", []);
    const firstItem = get(spreadsheetData, 0);

    if (!columnKey) {
      return;
    }

    if (!isObject(firstItem) || !isArray(spreadsheetData)) return null;

    setBlock({
      data: {
        ...activeBlock,
        columnKey,
        columns: generateColumns(spreadsheetData, columnKey),
      },
    });
  };

  const generateColumns = (spreadsheetData, columnKey) => {
    if (!columnKey) return [];

    const { items, dupes } = getDuplicates(spreadsheetData, columnKey);
    const uniqueValues = dupes.length > 1 ? dupes : items;
    return uniqueValues.map((value) => ({ label: value, value }));
  };

  const handleTable = (sheetId) => {
    const sheet = savedSpreadsheets.find((s) => s.id === sheetId);

    const { primaryImage, primaryLabel, secondaryLabel } = getHeaders(sheet);

    const headers = get(sheet, "headers", []);

    const config = get(sheet, ["field_data", "config"], {});

    const typeMap = {
      DateTimePicker: "date",
      Checkbox: "boolean",
      Switch: "boolean",
      ImageUpload: "image",
      Select: "badge",
    };

    let columnData = {
      ...config,
    };

    Object.keys(config).forEach((k) => {
      const component = get(config, [k, "componentId"]);
      const columnType = get(typeMap, component);
      if (columnType) {
        columnData[k] = { columnType };
      }
    });

    // Initialize order with headers
    let order = [...headers];

    // Reorder based on primaryImage, primaryLabel, and secondaryLabel
    if (secondaryLabel) {
      order = [secondaryLabel, ...order.filter((h) => h !== secondaryLabel)];
    }
    if (primaryLabel) {
      order = [primaryLabel, ...order.filter((h) => h !== primaryLabel)];
    }
    if (primaryImage) {
      order = [primaryImage, ...order.filter((h) => h !== primaryImage)];
    }

    setBlock({
      data: {
        ...activeBlock,
        label: get(sheet, "title"),
        spreadsheet: sheetId,
        columnData: { config: columnData, order },
      },
    });
  };

  // CREATE MULTIPLE RECORDS - AI VIEW
  const createMultipleRecords = ({ sheetId, rows }) => {
    const body = {
      spreadsheet_id: sheetId,
      method: "CREATE_MULTIPLE_ROWS",
      rows,
      domain: getCurrentDomain(),
    };

    return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
      const newRecords = get(response, "data", []);

      updateMatchingSheets({ type: "create_multiple", sheetId, newRecords });

      return newRecords;
    });
  };

  // UPDATE AND RETREIVE CELLS
  const updateAndRetreive = ({ spreadsheetId, updates, results }) => {
    const body = {
      spreadsheet_id: spreadsheetId,
      updates,
      results,
      method: "UPDATE_AND_RETREIVE",
      domain: getCurrentDomain(),
    };

    return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
      const data = get(response, ["data"], {});
      return data;
    });
  };

  return {
    //
    handleTable,
    handleGrid,
    handleCustom,
    handleForm,
    handleInfoList,
    handleKanban,
    populateKanbanColumns,
    handleCalendar,
    handleChart,
    handleStat,
    //
    refreshSheet,
    getRecords,
    getRecord,
    createRecord,
    createMultipleRecords,
    updateRecord,
    deleteRecord,
    updateAndRetreive,
  };
};

export default useSpreadsheetRequests;
